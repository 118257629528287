import React, {useEffect, useState} from "react"
import styled, {css} from "styled-components";
import {useLanguageHook} from "../../hooks/useLanguageHook";
import {CmsUtils} from "../../utils/cmsUtils";
import Layout from "../layout";
import SEO from "../seo";
import HeaderSection from "../sections/HeaderSection";
import MyContainer from "../containers/MyContainer";
import {graphql, useStaticQuery} from "gatsby";
import FooterSection from './../../components/sections/FooterSection';
export default function BlogTemplate({ pageContext, ...rest}) {
    const {page, posts, countPages} = pageContext;
    const {site} = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                    description
                    author
                    keywords
                    releaseDate
                    siteUrl
                }
            }
        }
    `)

    const title = `Blog » ${site.siteMetadata.author}`
    const intl = useLanguageHook();

    return (
        <Layout>
            <SEO title={title}
                 pageUrl={`${site.siteMetadata.siteUrl}/blog/`}
            />

            <Wrapper>

                <HeaderSection/>
                <MyContainer>
                    <PostsWrapper>
                        <PageTitleBlock>
                            <h3>{intl.formatMessage({id: 'header.menu.blog'})}</h3>
                        </PageTitleBlock>
                        {
                            (posts != null && posts.length) && (
                                <ListContainer>
                                    {posts.map(({Page, Content, updated_at, publish_en, publish_ru}, index) => {
                                        const {
                                            preview_en,
                                            preview_ru,
                                            cover_img_ru,
                                            cover_img_en,
                                            published_en,
                                            published_ru
                                        } = Content;

                                        const {
                                            page_url_en,
                                            page_url_ru,
                                            title_en,
                                            title_ru,
                                        } = Page;

                                        const publishRU = process.env.GATSBY_LANG_ENV === "ru" && (published_ru == null || published_ru);
                                        const publishEN = process.env.GATSBY_LANG_ENV === "en" && (published_en == null || published_en);

                                        const title = process.env.GATSBY_LANG_ENV === "en" ? title_en : title_ru;
                                        const content = process.env.GATSBY_LANG_ENV === "en" ? preview_en : preview_ru;
                                        const coverImg = process.env.GATSBY_LANG_ENV === "en" ? cover_img_en : cover_img_ru;
                                        const url = process.env.GATSBY_LANG_ENV === "en" ? page_url_en : page_url_ru;

                                        return (publishRU || publishEN) ? (
                                            <ListItem key={index}>
                                                <ItemCover img={CmsUtils.imageUrl(coverImg != null  ? coverImg.url : '')} onClick={() => {
                                                    window.location.pathname = `/blog/${url}`;
                                                }}/>
                                                <ItemTitle
                                                    href={`${process.env.GATSBY_LANG_ENV === "en" ? '' : '/ru'}/blog/${url}`}>{title}</ItemTitle>
                                            </ListItem>
                                        ) : null;
                                    })}
                                </ListContainer>
                            )
                        }
                    </PostsWrapper>
                </MyContainer>
                <FooterSection/>

            </Wrapper>

        </Layout>
    )
}

const Wrapper = styled.div`
  font-family: Avenir Next Cyr;
  overflow: hidden;
`;


const PostsWrapper = styled.div`
  padding-top: 17px;
  padding-bottom: 37px;
  margin-top: 100px;
`;


const PageTitleBlock = styled.div`
  color: #fff;
  font-size: 34px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  flex: 1 1 350px;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const ItemTitle = styled.a`
  color: #fff;
  text-align: left;
  font-size: 21px;
  font-weight: 750;
  cursor: pointer;
  transition: .5s;
  text-decoration: none;

  &:hover {
    color: #fcea02;
    text-decoration: underline;
  }
`;

const ItemCover = styled.div`
  height: 200px;
  max-width: 350px;
  width: 100%;
  align-self: center;
  margin-bottom: 10px;
  cursor: pointer;

  ${props => props.img && css`
    background-image: url(${props.img});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `}
`;


